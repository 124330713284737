* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Assistant", sans-serif;
}

#head {
  position: relative;
  z-index: 2000;
}

h1 {
  font-size: 100px;
}

@media (max-width: 960px) {
  h1 {
    font-size: 70px;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 50px;
  }
}

@media (max-width: 300px) {
  h1 {
    font-size: 40px;
  }
}

@media (max-height: 420px) {
  h1 {
    margin-top: -20px;
  }
}

@media (max-height: 300px) {
  h1 {
    font-size: 40px;
  }
}
