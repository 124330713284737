.footer-cont {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem 2rem 2rem;
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  color: #fff;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

#subscription-call {
  margin-bottom: 24px;
  font-size: 24px;
}

#subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

#footer-input {
  margin: 0 10px 16px 0;
  padding: 8px 20px;
  border-radius: 2px;
  border: 1px solid #fff;
  outline: none;
  font-size: 18px;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto 20px;
}

.footer-infos {
  width: 30%;
  display: flex;
  flex-direction: row;
}

.info-category {
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  gap: 8px;
}

.info-category h2 {
  margin-bottom: 16px;
}

.info-category a {
  text-decoration: none;
  color: #fff;
}

.info-category a:hover {
  color: #d4d3d3;
  transition: 0.3s ease-out;
}

.footer-rights {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.website-rights {
  align-self: end;
  color: #fff;
}

.footer-socials {
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.social-icons {
  width: 240px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.social-icon-link {
  font-size: 24px;
  color: #fff;
}

.footer-up-icon {
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  padding: 5px 7px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
}

.fa-chevron-up {
  color: #000;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
}

.footer-up-icon a.active {
  border: none;
}

@media (max-width: 820px) {
  #footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding-top: 2rem;
  }

  .footer-infos {
    width: 100%;
    justify-content: center;
    gap: 4rem;
  }

  .info-category {
    width: 100px;
    align-items: center;
  }

  .footer-socials {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }

  .footer-up-icon {
    right: 1rem;
    bottom: 1rem;
  }

  .fa-chevron-up {
    font-size: 16px;
    line-height: 18px;
  }
}
