.hero-cont {
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  text-align: center;
  background: url("/public/images/handicraft.jpg") center center/cover no-repeat;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.hero-cont > h1 {
  margin-top: -100px;
  color: #fff;
}

.hero-cont > p {
  margin-top: 8px;
  font-size: 32px;
  color: #fff;
}

.hero-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  margin-top: 32px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media (max-width: 960px) {
  .hero-cont > h1 {
    margin-top: -150px;
  }
}

@media (max-width: 768px) {
  .hero-cont > h1 {
    margin-top: -100px;
  }

  .hero-cont > p {
    font-size: 30px;
  }

  .hero-btns {
    flex-direction: column;
  }

  .btn {
    width: 100%;
  }
}

@media (max-width: 300px) {
  .hero-cont {
    padding: 0 1rem;
  }
  .hero-cont > p {
    font-size: 25px;
  }
}

@media (max-height: 420px) {
  .hero-cont > h1 {
    margin-top: -20px;
  }

  .hero-cont > p {
    font-size: 30px;
  }
}

@media (max-height: 300px) {
  .hero-cont {
    height: 100vh;
  }
  .hero-cont > h1 {
    margin-top: -20px;
  }

  .hero-cont > p {
    font-size: 25px;
  }

  .btn {
    font-size: 18px;
  }
}
