.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn-primary {
  background-color: #fff;
  color: #242424;
  border: 1px solid #fff;
}

.btn-primary:hover {
  background-color: #d4d3d3;
}

.btn-dark {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  color: #fff;
}

.btn-outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid #fff;
  transition: all 0.3s ease-out;
}

.btn-medium {
  padding: 8px 20px;
  font-size: 20px;
}

.btn-large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn-medium:hover,
.btn-large:hover {
  background: #fff;
  color: #242424;
  transition: all 0.3s ease-out;
}
