.header-section {
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  text-align: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  color: #fff;
}
