.nav-cont {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 29999;
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  font-size: 1.2rem;
  font-weight: 700;
}

#navbar {
  max-width: 1500px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-logo {
  display: flex;
  align-items: center;
  justify-self: start;
  font-family: "PT Sans", sans-serif;
  font-size: 2rem;
  text-decoration: none;
  margin-left: 16px;
  color: #fff;
  cursor: pointer;
}

#nav-logo {
  width: auto;
  height: 1.6rem;
  margin-left: 0.5rem;
}

.nav-menu {
  width: 60vw;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  justify-content: end;
  text-align: center;
}

.nav-item {
  height: 80px;
  list-style: none;
}

.nav-links {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-family: "PT Sans", sans-serif;
  color: #fff;
}

.nav-links:hover {
  padding-bottom: 1.2rem;
  transition: all 0.2s ease-out;
}

a.active {
  border-bottom: 4px solid #fff;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .navbar-logo {
    margin: 0;
  }

  .nav-menu {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    left: 0;
    z-index: 1;
    background: #242222;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-links {
    width: 100%;
    display: table;
    text-align: center;
    padding: 2rem;
  }

  .nav-links:hover {
    border-radius: 0;
    background-color: #fff;
    color: #242424;
  }

  a.active {
    border: none;
    text-decoration: underline;
    text-decoration-style: line;
    text-decoration-thickness: 20%;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    width: 80%;
    display: block;
    text-align: center;
    margin: 2rem auto;
    padding: 14px 20px;
    border: 1px solid #fff;
    border-radius: 4px;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}
