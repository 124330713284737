.card-item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.card-item-link {
  width: 100%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  text-decoration: none;
}

.card-item-pic {
  width: 100%;
  position: relative;
  padding-top: 67%;
  overflow: hidden;
}

.card-item-pic img {
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  transition: all 0.2s linear;
}

.card-item-pic img:hover {
  transform: scale(1.1);
}

.card-pic-label {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.2rem 1rem;
  border-radius: 0 10px 0 10px;
  background-color: #0e2f3f;
  color: #f8f7f1;
}

.card-item-text {
  padding: 20px 30px 30px;
  font-size: 18px;
  line-height: 24px;
  color: #252e48;
}

@media (max-width: 820px) {
  .card-item {
    margin: 1rem 0;
  }

  .card-item-text,
  .card-pic-label {
    font-size: 14px;
  }
}
